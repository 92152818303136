<template>
  <!-- Modal -->
  <div class="modal" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de usuarios por compañía</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Usuario:</label>
                  <select v-model="item.usu_id" class="form-control col-md-6">
                    <option v-for="usuario in usuarios" v-bind:value="usuario._id">
                      {{ usuario.usu_nmbre + ' ' + usuario.usu_aplldo }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Tipo de usuario:</label>
                  <select v-model="item.dep_id" class="form-control col-md-6">
                    <option v-for="tpousu in tpousus" v-bind:value="tpousu._id">
                      {{ tpousu.tus_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Activo:</label>
                  <input type="checkbox" class="form-control col-md-6" v-model="item.uxc_activo">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Ver todos los documentos:</label>
                  <input type="checkbox" class="form-control col-md-6" v-model="item.uxc_ver_docs">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Modificar todos los documentos:</label>
                  <input type="checkbox" class="form-control col-md-6" v-model="item.uxc_modificar_docs">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Archivar todos los documentos:</label>
                  <input type="checkbox" class="form-control col-md-6" v-model="item.uxc_archivar_docs">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('search', item)">Buscar usuarios por compañía</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      usuarios: [],
      tpousus: []
    }
  },
  mounted: function() {

  },
  created: function() {
    this.fetchUsuario();
    this.fetchTpousu();
  },
  methods: {
    fetchUsuario()
    {
      //alert(String(this.$store.state.company));
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.usu_nmbre > b.usu_nmbre) { return 1; };
          if (a.usu_nmbre < b.usu_nmbre) { return -1; };
          return 0;
        });
        this.usuarios = list;
      });
    },
    fetchTpousu()
    {
      //alert(String(this.$store.state.company));
      let uri = '/tpousus/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.tus_nmbre > b.tus_nmbre) { return 1; };
          if (a.tus_nmbre < b.tus_nmbre) { return -1; };
          return 0;
        });
        this.tpousus = list;
      });
    }
  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
