<template>
<div align="center">
  <br>
  <div class="card rounded shadow-lg" align="left">
    <div class="card-body">
      <div class="card-title">
        <h1>Usuarios por Compañía</h1>
      </div>
      <br>
      <div class="row">
        <div class="">
          <div v-if="filter">
            <button v-on:click="closeSearch" class="btn btn-link"><i data-feather="arrow-left-circle"></i>Cerrar búsqueda</button>
          </div>
          <div class="">
            <button v-on:click="$router.replace({ name: 'CreateUsuxcmp', params: {add: true} });" class="btn btn-success"><i data-feather="plus-circle"></i>Crear Usuario por compañía</button>
          </div>
          <br>
          <div>
            <form v-on:submit.prevent="searchItems({ keys: keywords })">
              <div class="">
                <button type="button" v-on:click="newSearch()" class="btn btn-success" data-toggle="modal" data-target="#searchModal">
                  Búsqueda avanzada
                </button>
              </div>
              <searchusuxcmp v-on:search="searchItems" v-bind:item="search"></searchusuxcmp>
            </form>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="">
          <br>
          <v-spacer></v-spacer>
          <!--
          <v-text-field
            v-model="search_items"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        -->
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="pagination"
            :search="search_items"
            select-all
            item-key="_id"
            :loading="false"
            class="elevation-1"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th>
                  <input type="checkbox" v-model="allChecked" v-on:click="checkAllItems">
                </th>
                <th></th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td>
                <input type="checkbox" v-bind:value="props.item._id" v-model="delItems">
              </td>
              <td><a href="#" v-on:click.prevent="editItem(props.item._id)"><edit-icon class="custom-class"></edit-icon></a></td>
              <td>{{ props.item.usuario_usuxcmps[0].usu_nmbre }}</td>
              <td>{{ props.item.tpousu_usuxcmps[0].tus_nmbre }}</td>
              <td>{{ props.item.uxc_activo | formatBoolean }}</td>
              <td>{{ props.item.uxc_ver_docs | formatBoolean }}</td>
              <td>{{ props.item.uxc_modificar_docs | formatBoolean }}</td>
              <td>{{ props.item.uxc_archivar_docs | formatBoolean }}</td>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Su búsqueda de "{{ search_items }}" no tuvo resultados.
            </v-alert>
          </v-data-table>
          <br>
          <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
            <i data-feather="delete"></i>Eliminar
          </button>
          <!--{{delItems}}-->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
//import { VAlert, VIcon, VProgressLinear, VSpacer, VDataTable } from 'vuetify/lib';
import { formatBoolean } from './../../plugins/filters';
import SearchUsuxcmp from './SearchUsuxcmp.vue';
import { EditIcon } from 'vue-feather-icons';

export default {
  components: {
    //VAlert, VIcon, VProgressLinear, VSpacer, VDataTable,
    'searchusuxcmp': SearchUsuxcmp,
    EditIcon
  },
  data(){
    return{
      items: [],
      usuarios: [],
      tpousus: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      search_items: '',
      pagination: {
        sortBy: 'name'
      },
      headers: [
        { text: 'Usuario', value: 'usu_id' },
        { text: 'Tipo de usuario', value: 'tus_id' },
        { text: 'Activo', value: 'uxc_activo' },
        { text: 'Ver todos los documentos', value: 'uxc_ver_docs' },
        { text: 'Modificar todos los documentos', value: 'uxc_modificar_docs' },
        { text: 'Archivar todos los documentos', value: 'uxc_archivar_docs' },
        { text: '', align: 'center', sortable: false, value: 'Editar' }
      ]
    }
  },
  filters: {
    formatBoolean
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    //feather.replace();
  },
  created: function()
  {
    this.fetchUsuario();
    this.fetchTpousu();

    this.searchItems({ cmp_id: this.$store.state.company });
  },
  methods: {
    fetchUsuario()
    {
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        this.usuarios = response.data;

      });
    },
    fetchTpousu()
    {
      let uri = '/tpousus/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpousus = response.data;

      });
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/uxc/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems()
    {
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (var i = 0; i < this.delItems.length; i++){
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = '/uxc/delete/' + this.delItems[i];
            this.axios.get(uri)
            .then(res => {
              //this.items.splice(index, 1);
              if (this.search){
                this.searchItems(this.search);
              }
            })
            .catch(err => {
              console.log(err);
              for (var m = 0; m < this.items.length; m++){
                if (this.delItems[i] == this.items[m]._id){
                  alert('No se ha podido eliminar el item ' + this.items[m]);
                  m = this.items.length;
                }
              }
            });
          }
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    newSearch () {
      this.search = {};
    },
    searchItems(p){
      if (p != null){
        this.showProgress = true;
        this.debug = p;

        p.cmp_id = String(this.$store.state.company);
        this.message = 'Buscando usuarios por compañía';
        let uri = '/uxc/search';
        this.axios.post(uri, p)
        .then((response) => {
          this.items = response.data;
          this.search = p;
          this.showProgress = false;
          this.delItems = [];

        })
        .catch(err => {
          console.log(err);
          this.showProgress = false;
          this.message = '¡Error al buscar los documentos' + err;
          if(err.response.status === 401) {
            this.$router.push({ name: '/' });
          }
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
    },
    createItem(){
      this.$router.replace({ name: 'CreateUsuxcmp', params: {add: true} });
    },
    editItem(id){
      this.$router.replace({ name: 'CreateUsuxcmp', params: {id: id, add: false, search: this.search } });
    }
  }
}
</script>
